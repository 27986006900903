import axios from 'axios'
import showToast from '@/config/Toast'
const instance = axios.create({
    baseURL: process.env.VUE_APP_BASE_URL
  });
  const AUTH_TOKEN =localStorage.getItem('TOKEN')
  instance.defaults.headers.common['x-access-token'] = AUTH_TOKEN
  instance.interceptors.response.use(function (response) {
    // Any status code that lie within the range of 2xx cause this function to trigger
    // Do something with response data
    return response;
  }, function (error) {
    let  err = error.response?.data
    console.log(err)
    if(err){
      showToast(err)
    }else{
      showToast('Internet Error / Server Not Respond')
    }
    // Any status codes that falls outside the range of 2xx cause this function to trigger
    // Do something with response error
    // return Promise.reject(error);
  });
  export default  instance;