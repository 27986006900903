import { defineStore } from 'pinia'
import API from '@/config/API.js'
export const Auth = defineStore('auth', {
    state: () => {
        return {
          auth_token:'',
          buttonLoader:false,
          user:JSON.parse(localStorage.getItem('user'))
        }
      },
  actions: {
    async login(payload){
            this.buttonLoader = true
            const response = await API.post("user/login",payload)
            this.buttonLoader = false
            localStorage.setItem("TOKEN", response.data.token);
            localStorage.setItem("auth", response.data.auth);
            this.user=response.data
            localStorage.setItem("user",JSON.stringify(response.data))
            window.location.href="/" 
    },
  },
  })