import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import { createPinia } from 'pinia'
import 'vuetify/styles'
import { createVuetify } from 'vuetify'
import Toaster from '@meforma/vue-toaster';
import * as components from 'vuetify/components'
import * as directives from 'vuetify/directives'
const pinia = createPinia()
const vuetify = createVuetify({
  components,
  directives,
})


const app =createApp(App)
app.use(vuetify)
app.use(Toaster,{timeout: 3000,position: "top-right"},)
app.use(pinia)
app.use(router)
app.mount('#app')
