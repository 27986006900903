<template>
    <div id="kt_body" class="bg-body">
        <div class="d-flex flex-column flex-root">
			<div class="d-flex flex-column flex-column-fluid">
				<div class="d-flex flex-center flex-column flex-column-fluid p-10 pb-lg-10">
					<a href="index.html" class="mb-12 loginlogo">
						<!-- AL-Sharaf Group  -->
						<img src="@/assets/logo.jpeg" />
					</a>
					<div class="w-lg-500px bg-body rounded shadow-sm p-10 p-lg-15 mx-auto">
						<form  @submit.prevent="login" class="form w-100" novalidate="novalidate" id="kt_sign_in_form" action="#">
							<div class="text-center mb-10">
								<h1 class="text-dark mb-3">Sign In</h1>
								
							</div>
							<div class="fv-row mb-10">
								<label class="form-label fs-6 fw-bolder text-dark">Email</label>
								<input v-model.trim="$v.email.$model" 
								class="form-control form-control-lg form-control-solid"
								type="text" name="email" autocomplete="off"
								placeholder="email@yourdomain.com" 
								:class="[{ error: $v.email.$errors.length }]"
								/>
								<div v-if="$v.email.$error">
                                <div v-for="(error, index) in $v.email.$errors" :key="index" class="text-danger	mt-2" >
                                    {{ error.$message }}
                                </div>
								</div>
							</div>
							<div class="fv-row mb-10">
								<div class="d-flex flex-stack mb-2">
									<label class="form-label fw-bolder text-dark fs-6 mb-0">Type Password</label>
									<!-- <a href="password-reset.html">Forgot Password ?</a> -->
								</div>
								<input v-model.trim="$v.password.$model" :class="[{ error: $v.password.$errors.length }]" class="form-control form-control-lg form-control-solid" placeholder="******" type="password" name="password" autocomplete="off" />
								<div v-if="$v.password.$error">
                                <div v-for="(error, index) in $v.password.$errors" :key="index" class="text-danger	mt-2" >
                                    {{ error.$message }}
                                </div>
								</div>
							</div>
                            
                            <div class="text-center loginbtn">
                                <button type="submit"  class="btn btn-lg btn-primary w-100 mb-5" :disabled="store.buttonLoader"> 
								<span v-if="!store.buttonLoader">Login</span> <span style="height: 1.5rem; width: 1.5rem" v-if="store.buttonLoader"  class="spinner-border p-0 m-0"></span> </button>
							</div>
						</form>
					</div>
				</div>
			</div>
		</div>
        </div>
  </template>
  <script setup>
    /* eslint-disable */
import { reactive } from 'vue';
import { Auth } from '@/store/auth';
import { useVuelidate } from '@vuelidate/core'
import {  required ,helpers,email} from '@vuelidate/validators'
const store = Auth()
const fromData = reactive({
	email:'',
	password:'',
})

const logo = process.env.VUE_APP_COMPANY_LOGO


const rules = {
	     email: {
           email,
           required: helpers.withMessage("Email is required", required),
          },
          password:{
             required: helpers.withMessage("password is required", required),
          }  
    };

const $v = useVuelidate(rules,fromData)

const login = ()=>{
	$v.value.$touch();
	if (!$v.value.$invalid){
		store.login(fromData)
	}
	
}


  </script>

  <style scoped>
.loginlogo
 {
	font-size:25px;
	font-weight:800
 } 
 .loginlogo img {
	width:120px
 }
  </style>
  
  