
import { createRouter, createWebHashHistory } from "vue-router";
import TheLogin from "../views/TheLogin.vue";
const Main = ()=>import( "@/views/DashBoard.vue");
// const DashBoard = ()=>import( "@/components/DashBoard.vue");
const ContactPage =()=>import( "@/views/ContectUs.vue");
const Services = ()=>import("@/views/ServicesPage.vue")
const Team = ()=>import("@/views/TeamPage.vue")
const Testimonial = ()=> import ("@/views/TestimonialPage.vue")
const AboutUs = ()=> import ("@/views/AboutView.vue")
const Content =()=> import ("@/views/ContentPage.vue")
const Career =()=> import ("@/views/CareerView.vue")
const Image =()=>import("@/views/ImagesView.vue")
const Project = ()=>import('@/views/ProjectView.vue')
const routes = [
  {
    path: '/login',
    name: 'Login',
    component: TheLogin
  },
  {
    path: "/",
    name: "dashboard",
    component: Main,
    children:[
    {
      path: '/',
      component: AboutUs
    },
    {
      path: '/contact',
      name:'contact',
      component: ContactPage
    },
    {
      path: '/service',
      name:'service',
      component: Services
    },
    {
      path: '/team',
      name:'team',
      component: Team
    },
    {
      path: '/testimonial',
      name:'testimonial',
      component: Testimonial
    },
    {
      path: '/aboutus',
      name:'AboutUs',
      component: AboutUs
    },
    {
      path: '/content',
      name:'content',
      component: Content
    },
    {
      path: '/project',
      name:'project',
      component: Project
    },
    {
      path: '/career',
      name:'career',
      component: Career
    },
    {
      path: '/images',
      name:'images',
      component: Image
    },
  ]
},
];

const router = createRouter({
   history: createWebHashHistory(),
  routes,
});


router.beforeEach((to, from, next) => {
  window.scroll({
    top: 0,
    left: 0,
    behavior: "smooth",
  });

  const isAuthenticated = localStorage.getItem('auth');
  if (['/login'].includes(to.path) || isAuthenticated) {
    next(); // allow to enter route
  } else {
    if (['/footer'].includes(to.path)){
      next();
    }else{
     next('/login'); // go to '/login';
    } 
  }
})


export default router;
